import React, {Component} from "react";
import css from './price-calculator.module.scss';
import DropDownComponent from "../../../reuse/dropDown/drop-down.component";
import ResultTableComponent from "./resultTable.component.js/result-table.component";
import {Generate_hexService} from "../../../reuse/generate_hex/generate_hex.service";

const stack = [{
    angular: 'angular', name: 'Angular'
}, {
    react: 'react', name: 'React'
}]

const grading = [{
    junior: 'junior', name: 'Junior'
}, {
    middle: 'middle', name: 'Middle'
}, {
    senior: 'senior', name: 'Senior'
}];

const salary = {
    Junior: 1000, Middle: 2000, Senior: 3000
}

const cost = {
    value: 9200,
}

let numberArray = [];

for (let i = 1; i <= 10; i++) {
    numberArray.push({
        name: i
    });
}

class PricingCalculatorComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            list: [],
            valueData: null,
            setDefaultValue: false,
            updateDefaultValues: false,
            jobs: [],
            showLoader: true,
            prices: null
        }

        this.addValueToList = this.addValueToList.bind(this);
        this.setStateList = this.setStateList.bind(this);
        this.getPrices = this.getPrices.bind(this);
    }

    addValueToList() {
        let modifiedData = [...this.state.data, {id: Generate_hexService.create()}]

        this.setState({
            list: [...this.state.list, Object.assign(...modifiedData)], data: [],
        })
    }

    setStateList(list) {
        this.setState({
            list: list
        })
    }

    getPrices() {
        let id = null;
        for (let i = 0; i < this.state.data.length; i++) {
            if (Object.keys(this.state.data[i])[0] === 'ID') {
                id = this.state.data[i].ID;
            }
        }

        fetch('https://panel.stairwaysoft.com/api/wp/v2/calculator-search-specialisation-get-cost?id=' + id)
            .then(responce => responce.json())
            .then(data => {
                this.setState({
                        data: [...this.state.data, data]
                    }
                )
            })
            .then(this.addValueToList)
            .catch(error => console.log(error))
    }

    getValuesFromDropDown = (value) => {
        const array = this.state.data;
        const arrayOfObj = Object.entries(value).map((e) => ({[e[0]]: e[1]}));

        if (arrayOfObj.length === 1) {
            const i = array.findIndex(element => Object.keys(element)[0] === Object.keys(value)[0])
            if (i > -1) array[i] = value; else array.push(value);
            this.setState({
                data: array
            })
        } else {
            for (let j = 0; j < arrayOfObj.length; j++) {
                const i = array.findIndex(element => Object.keys(element)[0] === Object.keys(value)[j])
                if (i > -1) array[i] = arrayOfObj[j]; else array.push(arrayOfObj[j]);
                this.setState({
                    data: array
                })
            }
        }
    }

    handleContactUsBtn = () => {
        const contactForm = document.getElementById('contact-form');
        if (contactForm) {
            contactForm.scrollIntoView({block: "start", behavior: "smooth"});
        } else {
            this.props.history.push(`/#contact-form`);
        }
    };

    render() {

        return (
            <section className={`${css.visible}`}>
                <div className="container">
                    <div>
                        <h2 className={`${css.calculator_title}`}>Software Engineering Team cost calculator </h2>
                        <div className={`${css.filter_container}`}>

                            <DropDownComponent type={`Specialization:`}
                                               selectTitle={'Select position'}
                                               itemsList={this.props.jobs}
                                               getValuesFromDropDown={this.getValuesFromDropDown}
                                               data={this.state.data}
                                               updateDefaultValues={this.state.updateDefaultValues}/>
                            <DropDownComponent type={`Position:`}
                                               selectTitle={'Select position'}
                                               itemsList={grading}
                                               getValuesFromDropDown={this.getValuesFromDropDown}
                                               data={this.state.data}
                                               updateDefaultValues={this.state.updateDefaultValues}/>
                            <DropDownComponent type={`Number:`}
                                               selectTitle={'Select number'}
                                               itemsList={numberArray}
                                               getValuesFromDropDown={this.getValuesFromDropDown}
                                               data={this.state.data}
                                               updateDefaultValues={this.state.updateDefaultValues}/>

                            <button className={`${css.filter_btn}
            ${this.state.data.length <= 3 ? `${css.disabled}` : ''}`}
                                    disabled={this.state.data.length <= 3}
                                    onClick={this.getPrices}
                            >
                                Add to my team
                            </button>
                        </div>
                    </div>
                </div>

                {
                    this.state.list.length === 0 ? (
                            <div className={`${css.compare}`}>
                                <div className="container">
                                    <h2 className={`${css.compare_text}`}>
                                        Compose your team to see how much you can save with your dedicated development team
                                    </h2>
                                </div>
                            </div>
                        )
                        :
                        ''
                }

                <ResultTableComponent list={this.state.list} setStateList={this.setStateList}/>

            </section>
        )
    }
}

export default PricingCalculatorComponent;